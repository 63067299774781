import imageSlide1 from "@src/assets/images/new_banner.jpg";
import imageSlide2 from "@src/assets/images/SpecialtyFries.jpg";
import grandlaunchImage1 from "@src/assets/images/grandlaunch/SWPGrandLaunch_Option1.jpg/";
import grandlaunchImage2 from "@src/assets/images/grandlaunch/SWPGrandLaunch_Option2.jpg/";
import grandlaunchImage3 from "@src/assets/images/grandlaunch/SWPGrandLaunch_Option3.jpg/";
import grandlaunchImage4 from "@src/assets/images/grandlaunch/SWPGrandLaunch_Option4.jpg/";
import grandlaunchImage5 from "@src/assets/images/grandlaunch/SWPGrandLaunch_Option5.jpg/";
import grandlaunchImage6 from "@src/assets/images/grandlaunch/SWPGrandLaunch_Option6.jpg/";

import DocumentTitle from "@src/components/DocumentTitle";

function Home() {
  DocumentTitle("Potato Corner - Home");
  const SwpGrandLaunch = [
    {
      id: 1,
      picture: grandlaunchImage1,
    },
    {
      id: 2,
      picture: grandlaunchImage3,
    },
    {
      id: 3,
      picture: grandlaunchImage5,
    },
    {
      id: 4,
      picture: grandlaunchImage6,
    },
  ];

  return (
    <>
      <div className="container ">
        <section className="section-promotion">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={imageSlide1} className="d-block w-100" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={imageSlide2} className="d-block w-100" alt="..." />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>
        <div className="content-promotion py-4">
          <span>
            Potato Corner has been making the world a better place by serving
            freshly cooked and wholesome flavored french fries. It all began in
            the Philippines since 1992 and has made its way to 2000 stores in 16
            countries worldwide. Finally, Potato Corner has landed in Malaysia
            with its first store opening in Sunway Pyramid.{" "}
            <b>-and more to come!</b>
          </span>
        </div>
        <div className="swp-container">
          {SwpGrandLaunch.map((item) => (
            <div className="swp-item" key={item.id}>
              <img src={`${item.picture}`} alt="..." />
            </div>
          ))}
        </div>
        <div
          className="py-3 text-center color-gray small"
          style={{ color: "gray" }}
        >
          <span>
            <i>
              Grand Opening at Sunway Pyramid in November 2023 with a 1000 free
              fries giveaway. The turnout was massive and went viral<br></br>
              on all platforms. Until this day, we're still going head strong in
              serving you're the World's Best Flavored Fries.
            </i>
          </span>
        </div>
        <div className="content-about pb-3">
          <span>
            From high quality ingredients to premium taste, we select only the
            best out of the rest to serve you the World's Best Flavored Fries.
            <br></br>
            Each cup of fries are fried to a golden crisps and yeah you probably
            guessed it! We generously season them in our signature flavours that
            will surely blow your tastebuds away. We're passionate believers of
            making sure our customers always enjoy their fries fresh and we have
            an "Always Cooked Fresh" policy to guarantee you that!
          </span>
        </div>
      </div>
    </>
  );
}

export default Home;
