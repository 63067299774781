import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import DocumentTitle from '@src/components/DocumentTitle';
import Facebook from '@src/assets/images/socialmedia/facebook.png';
import Instagram from '@src/assets/images/socialmedia/instagram.png';
import Tiktok from '@src/assets/images/socialmedia/tiktok.png';
import axios from 'axios';
import Swal from 'sweetalert2';

function GetInTouch() {
    DocumentTitle("Potato Corner - Get In Touch")
    const [selectedCategories, setSelectedCategories] = useState('categories');
    const [selectedOutlets, setSelectedOutlets] = useState('outlets');
    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');
    const [phoneNumber, setPhoenNumber] = useState('');

    const setRequestPhone = (event) => {
        const newValue = event.target.value.replace(/[^0-9]/g, '');
        const limitedValue = newValue.slice(0, 10);
        setPhoenNumber(limitedValue);
    }

    const setRequestEmail = (event) => {
        setEmailAddress(event.target.value)
    };

    const sendEmail = async (event) => {
        event.preventDefault();
        const params = {
            Categories: selectedCategories,
            Outlets: selectedOutlets,
            Name: name,
            EmailAddress: emailAddress,
            Message: message,
            PhoenNumber: phoneNumber
        }
        await axios.post(`https://crm.rocks-foods.com/rocks-my/api/sende-mail`, params)
            .then(response => {
                if (response.status === 200) {
                    Swal.fire({
                        title: "You have sent the email already.",
                        icon: "success"
                    }).then(result => {
                        if (result.isConfirmed)
                            window.location.reload();
                    })
                }
            })
            .catch(error => console.error("Error Send Email: ", error));
    }

    return (
        <>
            <div className="container">
                <div className="section-head fw-bold mt-4 border-2 border-bottom">
                    <h2>Get In Touch</h2>
                </div>
                <div className="py-4">
                    <div className="content-contract">
                        <div className="icon-contract">
                            <a href="https://www.instagram.com/potatocornermalaysia/" target="_blank">
                                <img src={Instagram}></img>
                                <p>@potatocornermalaysia</p>
                            </a>
                        </div>
                        <div className="icon-contract">
                            <a href="https://www.facebook.com/PotatoCornerMY/" target="_blank">
                                <img src={Facebook}></img>
                                <p>Potato Corner MY</p>
                            </a>
                        </div>
                        <div className="icon-contract">
                            <a href="https://www.tiktok.com/@potatocornermy/" target="_blank">
                                <img src={Tiktok}></img>
                                <p>@potatocornermy</p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="form-concerns">
                    <h3 style={{ textAlign: 'center', paddingBottom: '10px' }}>Have other concerns? Drop us a Message!</h3>
                    <form className="form" onSubmit={sendEmail}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="custom-select-container">
                                        <select className="form-control" value={selectedCategories} onChange={e => setSelectedCategories(e.target.value)} style={{ color: selectedCategories !== 'categories' ? 'black' : '' }} required>
                                            <option
                                                value="categories"
                                                disabled={selectedCategories !== 'categories'}
                                            >
                                                Categories
                                            </option>
                                            <option value="Feedbacks">Feedbacks</option>
                                            <option value="Marketing​">Marketing​</option>
                                            <option value="Sponsorships​">Sponsorships​</option>
                                            <option value="Careers​">Careers​</option>
                                        </select>
                                        <div className="arrow-icon">
                                            <FontAwesomeIcon icon={faAngleDown} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-select-container">
                                        <select className="form-control" value={selectedOutlets} onChange={e => setSelectedOutlets(e.target.value)} style={{ color: selectedOutlets !== 'outlets' ? 'black' : '' }} required>
                                            <option
                                                value="outlets"
                                                disabled={selectedOutlets !== 'outlets'}
                                            >
                                                Outlets
                                            </option>
                                            <option value="Sunway Pyramid">Sunway Pyramid​</option>
                                            <option value="Aeon Nilai">Aeon Nilai​</option>
                                            <option value="1 Utama">1 Utama​</option>
                                            <option value="Central I-City">Central I-City</option>
                                            <option value="Sunway Velocity">Sunway Velocity​</option>
                                            <option value="Sunway Carnival">Sunway Carnival​</option>
                                            <option value="Setia City Mall​">Setia City Mall​​</option>
                                        </select>
                                        <div className="arrow-icon">
                                            <FontAwesomeIcon icon={faAngleDown} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder="Name" className="form-control" onChange={event => setName(event.target.value)} value={name} required></input>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        placeholder="Tel"
                                        className="form-control"
                                        onChange={setRequestPhone}
                                        value={phoneNumber}
                                        maxLength={10}
                                        required>
                                    </input>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="form-control"
                                        onChange={setRequestEmail}
                                        value={emailAddress}
                                        required>
                                    </input>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <textarea placeholder="We're all ears!" className="form-control textarea" onChange={event => setMessage(event.target.value)} value={message} required style={{ height: '278px' }}></textarea>
                                </div>
                            </div>
                            <div className="submit-form">
                                <input type="submit" value="SUBMIT" className="btn block m-b fs submit-contact" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default GetInTouch