import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { validateToken, refreshToken } from '@src/utils/authHelper';
import Swal from 'sweetalert2';



const BrandHome = () => {
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [voucher, setVoucher] = useState(null)

    useEffect(() => {
        const token = validateToken();

        if (!token) {
            Swal.fire({
                title: "An error occurred",
                text: "Please login again",
                icon: "error",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/login_brandamb'); // Redirect without reloading
                }
            });
            return; // Stop execution
        }

        // Fetch user profile
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_APP_API_PORT}/api/potatomy/user`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                });
                setUserProfile(response.data);
                setVoucher(response.data.vouchers);
                console.log(response.data);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    console.log("Token expired, attempting to refresh...");
                    try {
                        const newToken = await refreshToken();
                        if (newToken) {
                            console.log("Token refreshed, retrying user profile request...");
                            // Retry the request with the new token
                            const retryResponse = await axios.get(`${import.meta.env.VITE_APP_API_PORT}/api/potatomy/user`, {
                                headers: {
                                    Authorization: `Bearer ${newToken}`,
                                },
                            });
                            setUserProfile(retryResponse.data);
                            setVoucher(retryResponse.data.vouchers);
                            console.log(retryResponse.data);
                            return; // Exit after successful retry
                        }
                    } catch (refreshErr) {
                        console.error("Error refreshing token:", refreshErr);
                        setError("Session expired. Please log in again.");
                        localStorage.removeItem("jwt");
                        localStorage.removeItem("refreshToken");
                        navigate("/login_brandamb");
                        return;
                    }
                } else {
                    console.error("Error fetching user profile:", err);
                    setError("Failed to fetch user profile.");
                }
            } finally {
                setLoading(false);
            }
        };


        fetchUserProfile();
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className='container mt-3 vh-100'>
            <h1>Welcome, {userProfile?.user.firstName} {userProfile?.user.lastName || 'User'}!</h1>
            <div className="boxVouchers mt-3">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Desc</th>
                            <th>Left</th>
                        </tr>
                    </thead>
                    <tbody>
                        {voucher.map(item => (
                            <tr key={item.id}>
                                <td>{item.type}</td>
                                <td>{item.name}</td>
                                <td>{item.remaining}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default BrandHome;
