import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DocumentTitle from '@src/components/DocumentTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { ThreeCircles } from 'react-loader-spinner';

function Location() {

    DocumentTitle("Potato Corner - Location")

    const [store, setStore] = useState([]);
    const [storeDetail, setStoreDetail] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        await axios.get("https://crm.rocks-foods.com/rocks-my/api/stores")
            .then(response => {
                setStore(response.data);
                setIsLoading(false)
            })
            .catch(error => console.error("Error fetching data: ", error));
    };

    useEffect(() => {
        fetchData();
    }, []);

    // const getItemId = async (storeId) => {
    //     await axios.get(`https://crm.rocks-foods.com/rocks-my/api/store/${storeId}`)
    //         .then(response => {
    //             setStoreDetail(response.data);
    //             // Use Bootstrap's JS to handle showing the modal
    //             const myModal = new bootstrap.Modal(document.getElementById('exampleModal'));
    //             myModal.show();
    //         })
    //         .catch(error => console.error("Error fetching store details: ", error));
    // };
    // const handleClose = () => {
    //     const modal = bootstrap.Modal.getInstance(document.getElementById('exampleModal'));
    //     if (modal) {
    //         modal.hide();
    //     }
    //     // Manually remove the backdrop if it's not removed
    //     document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
    // };


    return (
        <>
            {
                isLoading ? (
                    <ThreeCircles
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
                        wrapperClass=""
                    />
                ) : (
                    <>
                        <div className='container-store'>
                            <div className='item-store'>
                                {store.map((item) => (
                                    <div key={item.id} className="card-store">
                                        <div className="card-image-location">
                                            <img src={`https://crm.rocks-foods.com/rocks-my/api/store/image/${item.fileName}`}></img>
                                        </div>
                                        <div className="card-storename">
                                            <span>{item.storeName}</span>
                                        </div>
                                        <div className="card-location">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'red', marginRight: '5px' }} /><span>{item.location}</span>
                                        </div>
                                        <div className="card-nearbylocation">
                                            <span>{item.nearbyPositions}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div >
                        {/* <div className="container">
                            <div className="section-head fw-bold mt-4 border-2 border-bottom">
                                <h2>Locations</h2>
                            </div>

                            <div className='row mt-4'>
                                {store.map((item) => (
                                    <div key={item.id} className="col-md-4 mt-3 col-12">
                                        <div className="col d-flex">
                                            <div className="icon-location">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'red' }} />
                                            </div>
                                            <div className="detail ml-3">
                                                <h5>{item.storeName}</h5>
                                                <a className="more-detail" href="#exampleModal" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => getItemId(item.id)}>
                                                    More Detail
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{storeDetail.storeName} </h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            <div className='col'>
                                                <img src={`https://crm.rocks-foods.com/rocks-my/api/store/image/${storeDetail.fileName}`} className='img-fluid' />
                                            </div>
                                            <div className='col '>
                                                <div className='mt-2 '>Opening hour: {storeDetail.openingHours}</div>
                                                <div className='mt-2 '>Direction: {storeDetail.nearbyPositions}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </>
                )
            }
        </>
    );
}

export default Location;
